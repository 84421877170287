<template>
  <div id="user-profile ">
    <b-overlay :show="loading">
      <b-navbar class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed">
        <slot name="navbar">
          <profile-header />
        </slot>
      </b-navbar>
      <div class="mt-4 mb-4">
        <center>
          <span style="font-size: 2rem; color: black" class="text-center">
            <b
              ><span class="text-primary">{{ data.title }}</span></b
            >
          </span>
          <br />
          <h5 class="mt-2">
            <p v-html="data.subtitle" />
          </h5>
        </center>
      </div>
      <div class="container" style="width: 90%">
        <section id="card-images">
          <!-- <b-col> -->
          <b-row>
            <b-col md="6" xl="4">
              <!-- <b-img :src="apiFile + data.thumbnail" class="img-fluid"></b-img> -->
              <b-card no-body :img-src="apiFile + data.thumbnail"> </b-card>
            </b-col>
            <b-col md="6" xl="8">
              <!-- <b-card> -->
              <!-- <h4>
                  <b>{{ data.title }} </b>
                </h4> -->
              <p v-html="data.content" />
              <!-- </b-card> -->
            </b-col>
          </b-row>
          <!-- </b-col> -->
        </section>
      </div>
    </b-overlay>
    <footer-home />
  </div>
</template>

<script>
import { BRow, BImg, BCol, BCard, BCardText, BCardTitle, BButton, BOverlay } from "bootstrap-vue";

import ProfileHeader from "./components/Navbar.vue";
import FooterHome from "./components/Footer.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BOverlay,
    FooterHome,
    ProfileHeader,
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },
  data() {
    return {
      profileData: {},
      data: [],
      loading: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let slug = "syarat-ketentuan";
      this.$store
        .dispatch("artikel/publicDetail", slug)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
  },
  created() {
    this.getData();
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
<style>
.containerhome {
  max-width: 100%;
  padding: 56px 120px 80px 120px;
  position: relative;
  box-sizing: border-box;
}
.containerkatamereka {
  max-width: 100%;
  padding: 0px 120px 0px 120px;
  position: relative;
  box-sizing: border-box;
}
</style>
